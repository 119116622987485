import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  ICustomerUserContext,
  CustomerUserContext,
} from './CustomerUserContext';
import useApi from 'contexts/api';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const CustomerUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userId } = useParams();
  const { customerUserControllerApi } = useApi();

  const getCustomerUserDetails = useCallback(async () => {
    if (!userId) return;

    try {
      return (
        await customerUserControllerApi.getCustomerUserDetailsById({
          id: Number(userId),
        })
      ).data;
    } catch {}
  }, [userId, customerUserControllerApi]);

  const {
    resource: customerUser,
    fetch,
    isLoading,
  } = useAsyncResource({
    fetchResource: getCustomerUserDetails,
  });

  const contextValue = useMemo(
    (): ICustomerUserContext => ({
      customerUser,
      isLoading,
      fetch,
    }),
    [customerUser, fetch, isLoading],
  );

  return (
    <CustomerUserContext.Provider value={contextValue}>
      {children}
    </CustomerUserContext.Provider>
  );
};
