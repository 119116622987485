import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const NotFoundTitle: FC<CommonSvgProps> = (props) => (
  <svg
    width="384"
    height="161"
    viewBox="0 0 384 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M350.066 155V119.8H273.266V109.2L350.466 6H362.066V108.8H379.266V119.8H362.066V155H350.066ZM283.866 113.4L282.266 108.8H350.066V17.8L354.866 19L283.866 113.4Z"
      fill="white"
    />
    <path
      d="M192.333 157.402C180.733 157.402 170.533 154.202 161.733 147.802C153.066 141.268 146.266 132.202 141.333 120.602C136.533 109.002 134.133 95.6016 134.133 80.4016C134.133 65.0682 136.533 51.6682 141.333 40.2016C146.266 28.6016 153.066 19.6016 161.733 13.2016C170.399 6.80156 180.533 3.60156 192.133 3.60156C203.599 3.60156 213.666 6.80156 222.333 13.2016C230.999 19.6016 237.733 28.6016 242.533 40.2016C247.466 51.6682 249.933 65.0682 249.933 80.4016C249.933 95.7349 247.533 109.202 242.733 120.802C237.933 132.268 231.199 141.268 222.533 147.802C213.866 154.202 203.799 157.402 192.333 157.402ZM192.333 145.002C201.533 145.002 209.466 142.268 216.133 136.802C222.799 131.335 227.933 123.802 231.533 114.202C235.266 104.468 237.133 93.2016 237.133 80.4016C237.133 67.4682 235.266 56.2016 231.533 46.6016C227.933 36.8682 222.799 29.3349 216.133 24.0016C209.466 18.5349 201.466 15.8016 192.133 15.8016C182.933 15.8016 174.933 18.5349 168.133 24.0016C161.466 29.3349 156.266 36.8682 152.533 46.6016C148.933 56.3349 147.133 67.6016 147.133 80.4016C147.133 93.0682 148.933 104.268 152.533 114.002C156.266 123.735 161.533 131.335 168.333 136.802C175.133 142.268 183.133 145.002 192.333 145.002Z"
      fill="white"
    />
    <path
      d="M81.9016 155V119.8H5.10156V109.2L82.3016 6H93.9016V108.8H111.102V119.8H93.9016V155H81.9016ZM15.7016 113.4L14.1016 108.8H81.9016V17.8L86.7016 19L15.7016 113.4Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M192.5 93C199.956 93 206 86.9558 206 79.5C206 72.0442 199.956 66 192.5 66C185.044 66 179 72.0442 179 79.5C179 86.9558 185.044 93 192.5 93Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M192.5 150C231.436 150 263 118.436 263 79.5C263 40.5639 231.436 9 192.5 9C153.564 9 122 40.5639 122 79.5C122 118.436 153.564 150 192.5 150Z"
      fill="url(#paint0_linear_13373_23565)"
    />
    <path
      opacity="0.9"
      d="M192.5 110C209.345 110 223 96.3447 223 79.5C223 62.6553 209.345 49 192.5 49C175.655 49 162 62.6553 162 79.5C162 96.3447 175.655 110 192.5 110Z"
      fill="url(#paint1_linear_13373_23565)"
    />
    <path
      d="M266 6.10547C207.901 64.7407 164.636 108.405 118.963 154.499"
      stroke="#21252C"
      stroke-dasharray="2 2"
    />
    <path
      d="M117.628 7.29166L269 156.098"
      stroke="#21252C"
      stroke-dasharray="2 2"
    />
    <path
      d="M192.5 85C195.538 85 198 82.5376 198 79.5C198 76.4624 195.538 74 192.5 74C189.462 74 187 76.4624 187 79.5C187 82.5376 189.462 85 192.5 85Z"
      fill="#21252C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_13373_23565"
        x1="216"
        y1="61.0357"
        x2="132.071"
        y2="150"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_13373_23565"
        x1="202.667"
        y1="71.5119"
        x2="166.357"
        y2="110"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0.945098" />
      </linearGradient>
    </defs>
  </svg>
);
