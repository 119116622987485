import { Typography } from '@mui/material';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import useUser from 'contexts/user';
import {
  UserChangedWindTurbineNameDto,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const WindTurbineDetailsChangedTemplate: FC<
  UserChangedWindTurbineNameDto
> = ({
  subjectUser,
  previousAssetNameForUser,
  currentAssetNameForUser,
  eventDateTime,
}) => {
  const { user } = useUser();

  return (
    <>
      <Trans
        i18nKey="components.historyLog.templates.asset.activities.windTurbineDetailsChanged"
        values={{ eventDateTime }}
        components={{
          userLink:
            subjectUser?.userRole ===
            UserUserRoleEnum.USER_PORTAL_SUPER_ADMIN ? (
              // TODO: replace user.username with subjectUser.username after BE update
              <Typography variant="bodyMStrong">{user.username}</Typography>
            ) : (
              <UserIdLink
                to={routePaths.users.profile(subjectUser?.id!).historyLog}
              >
                {/* TODO: replace with subjectUser.username after BE update */}
                {subjectUser?.userId}
              </UserIdLink>
            ),
        }}
      />
      <br />
      <Trans
        i18nKey="components.historyLog.templates.asset.activities.windTurbineNameChanged"
        values={{
          previous: previousAssetNameForUser,
          current: currentAssetNameForUser,
        }}
        components={{ bold: <strong /> }}
      />
    </>
  );
};
