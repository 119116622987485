import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { fieldsToSend } from './constants';
import {
  getBreadcrumbItems,
  getFilterByTypeValue,
  getFilterValues,
  getSortingItems,
  getTabs,
} from './helpers';
import { PageWrapper } from './styles';
import { WindTurbineHistoryLogItem } from './WindTurbineHistoryLogItem';
import { IdBlock } from '../WindTurbine/components';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { BreadcrumbItem } from 'components/common/Breadcrumbs/types';
import { dateStringToObject } from 'components/common/DatePicker';
import { Sorting } from 'components/common/Sorting';
import {
  FilterMenu,
  Search,
  TablePaginationVariants,
} from 'components/common/Table/CommonTable';
import Tabs from 'components/common/Tabs';
import { TabType, TabsComponentVariant } from 'components/common/Tabs/types';
import {
  HistoryLogContent,
  HistoryLogPagination,
} from 'components/shared/HistoryLog';
import { NoEventsBlock } from 'components/shared/NoEventsBlock';
import useApi from 'contexts/api';
import { FilterItem } from 'types/general';
import { formatObjFromQuery } from 'utils/functions/formatQuery';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

export const WindTurbineHistoryLog: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { publicId } = useParams();
  const { windTurbineControllerApi, historyLogControllerApi } = useApi();
  const { t } = useTranslation();
  const isDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('desktop'),
  );

  const {
    searchParams,
    setSearchParams,
    onSearch,
    onSortModelChange,
    onFilterModelChange,
    paginationModel,
    onPaginationModelChange,
  } = useDataGrid();

  const getWindTurbineData = useCallback(async () => {
    if (!publicId) return;

    try {
      return (await windTurbineControllerApi.getWindTurbine({ publicId })).data;
    } catch {}
  }, [publicId, windTurbineControllerApi]);

  const { resource: windTurbine } = useAsyncResource({
    fetchResource: getWindTurbineData,
  });

  const getWindTurbineHistoryLog = useCallback(async () => {
    if (!publicId) return;

    const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);
    const filterByTypeValue = getFilterByTypeValue(pathname);
    const dateObj = dateStringToObject(objFromQuery.dateRange as string);

    if (!objFromQuery.size || !objFromQuery.sort) return;

    try {
      return (
        await historyLogControllerApi.getHistoryLogs({
          ...objFromQuery,
          assetPublicId: publicId,
          type: filterByTypeValue,
          fromCreateDatetime: dateObj.from,
          toCreateDatetime: dateObj.to,
        })
      ).data;
    } catch {}
  }, [publicId, searchParams, pathname, historyLogControllerApi]);

  const { resource: historyLog } = useAsyncResourceWithPulling({
    fetchResource: getWindTurbineHistoryLog,
    pullingInterval: 30,
  });

  const breadcrumbItems = useMemo(
    (): BreadcrumbItem[] => getBreadcrumbItems(windTurbine, t),
    [windTurbine, t],
  );

  const tabItems = useMemo((): TabType[] => {
    const parentPath = pathname.split('/').slice(0, -1).join('/');
    const currentUrlSearchParams = new URLSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '0',
    }).toString();

    return getTabs({ parentPath, t }).map((tabItem: TabType) => ({
      ...tabItem,
      onClick: () => {
        navigate({ pathname: tabItem.value, search: currentUrlSearchParams });
      },
    }));
  }, [pathname, searchParams, navigate, t]);

  const sortingItems = useMemo(() => getSortingItems(t), [t]);

  const filterValues = useMemo(() => getFilterValues(t), [t]);

  const checkedFilterValues: Record<string, FilterItem> = useMemo(
    () => ({
      status: { value: searchParams.get('status') },
      dateRange: { value: searchParams.get('dateRange') },
    }),
    [searchParams],
  );

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  useEffect(() => {
    setSearchParams(
      (prev) => {
        if (prev.get('sort') === null) {
          prev.set('sort', 'create_datetime,DESC');
        }

        if (prev.get('size') === null) {
          prev.set('size', '20');
        }

        return prev;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  return (
    <PageWrapper>
      <Breadcrumbs items={breadcrumbItems} />
      <Box
        display="flex"
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        justifyContent="space-between"
        alignItems="flex-start"
        gap={{ mobile: 2.5, desktop: 0 }}
        mt={4}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="h1">
            {t('components.historyLog.title')}
          </Typography>
          <Box
            display="flex"
            flexDirection={{ mobile: 'column', tablet: 'row' }}
            alignItems={{ mobile: 'flex-start', tablet: 'center' }}
            gap={2}
          >
            <Typography variant="h5">{windTurbine?.name}</Typography>
            <IdBlock id={windTurbine?.publicId} />
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap={{ mobile: 'wrap', tablet: 'nowrap' }}
          alignItems={{ mobile: 'flex-start', tablet: 'center' }}
          gap={2}
          sx={{ width: { mobile: '100%', desktop: 'auto' } }}
        >
          <Search onSearch={onSearch} />
          <Sorting
            items={sortingItems}
            onSortModelChange={onSortModelChange}
            light
          />
          <FilterMenu
            filterValues={filterValues}
            checkedFilterValues={checkedFilterValues}
            onFilterModelChange={onFilterModelChange}
            light
          />
        </Box>
      </Box>
      <Box sx={{ mt: { mobile: 4, desktop: 6.5 } }}>
        <Tabs tabs={tabItems} componentVariant={TabsComponentVariant.Tabs} />
      </Box>
      <Box mt={3}>
        {historyLog?.empty ? (
          <NoEventsBlock />
        ) : (
          <HistoryLogContent>
            {historyLog?.content?.map((item) => (
              <WindTurbineHistoryLogItem key={item.id} {...item} />
            ))}
            <HistoryLogPagination
              variant={TablePaginationVariants.DEFAULT}
              count={historyLog?.totalElements}
              page={paginationModel.page}
              rowsPerPage={paginationModel.pageSize}
              rowsPerPageOptions={rowsPerPageOptions}
              onPaginationModelChange={onPaginationModelChange}
            />
          </HistoryLogContent>
        )}
      </Box>
    </PageWrapper>
  );
};
