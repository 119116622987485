import { styled } from '@mui/material';

import { NotFoundTitle } from 'components/icons';

export const NotFoundTitleStyled = styled(NotFoundTitle)(({ theme }) => ({
  flexShrink: 0,
  height: '161px',
  width: '384px',

  [theme.breakpoints.down('desktop')]: {
    height: '118px',
    width: '280px',
  },
}));
