import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/Tabs';
import { TabsComponentVariant } from 'components/common/Tabs/types';
import routePaths from 'constants/routePaths';
import useWindTurbine from 'contexts/WindTurbine';

export const WindTurbinePageTabs: FC = () => {
  const { windTurbine } = useWindTurbine();
  const { t } = useTranslation();

  const parentPath = useMemo(() => {
    if (!windTurbine?.publicId) return;

    return routePaths.parks
      .specific(windTurbine.location?.id!)
      .windTurbine(windTurbine.publicId);
  }, [windTurbine]);

  const tabs = useMemo(
    () => [
      {
        label: t('pages.turbine.tabs.generalInfo.title'),
        value: parentPath?.general || '',
      },
      {
        label: t('pages.turbine.tabs.statuses.title'),
        value: parentPath?.statuses || '',
      },
    ],
    [t, parentPath],
  );

  return (
    <Tabs
      tabs={tabs}
      componentVariant={TabsComponentVariant.Tabs}
      sx={{ flexGrow: 1 }}
    />
  );
};
