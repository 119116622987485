import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { WeatherPreview } from './components';
import {
  CountersWrapper,
  MainContentWrapper,
  ParkItemsCounter,
  StatsItem,
  StatsWrapper,
  Wrapper,
} from './styles';
import { ParkItemState } from './types';
import { Badge } from 'components/common/Badge';
import { BadgeSize, BadgeVariant } from 'components/common/Badge/types';
import { ChevronRightL } from 'components/icons';
import routePaths from 'constants/routePaths';
import {
  LocationWithWindTurbineMetricsDto,
  WindTurbineDtoOperationalStatusEnum,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';
import { convertWh } from 'utils/functions/converters';

const ParkStatesBadgeMapping = {
  [ParkItemState.Active]: BadgeVariant.Active,
  [ParkItemState.Error]: BadgeVariant.Error,
  [ParkItemState.Inactive]: BadgeVariant.Inactive,
};

interface Props {
  data: LocationWithWindTurbineMetricsDto;
}

export const ParkCard: FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    locationId,
    locationName,
    turbineCount,
    activeStatusCount,
    inactiveStatusCount,
    errorStatusCount,
    totalActiveEnergy,
  } = data;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const onBadgeClick = useCallback(
    (e: MouseEvent<HTMLElement>, search: string) => {
      e.preventDefault();
      navigate({
        pathname: routePaths.parks.specific(locationId!).root,
        search,
      });
    },
    [navigate, locationId],
  );

  return (
    <Wrapper>
      <MainContentWrapper>
        <ParkItemsCounter>
          <Typography variant="bodyMStrong">
            <Trans
              i18nKey="pages.parks.card.items"
              values={{ itemsCount: turbineCount }}
            />
          </Typography>
          <ChevronRightL />
        </ParkItemsCounter>
        <Typography variant="h3" sx={{ mt: { mobile: 1, desktop: 1.5 } }}>
          {locationName}
        </Typography>
        <CountersWrapper>
          <Box display="flex" gap={1}>
            {!!activeStatusCount && (
              <Badge
                key={ParkItemState.Active}
                variant={ParkStatesBadgeMapping[ParkItemState.Active]}
                size={BadgeSize.Meduim}
                text={
                  isDesktop ? (
                    <Trans
                      i18nKey={`pages.parks.card.itemsStates.${[
                        ParkItemState.Active,
                      ]}`}
                      values={{ count: activeStatusCount }}
                    />
                  ) : (
                    activeStatusCount
                  )
                }
                onClick={(e) =>
                  onBadgeClick(
                    e,
                    `?operationalStatus=${WindTurbineDtoOperationalStatusEnum.ACTIVATED}`,
                  )
                }
                dataTestId="park-active-badge"
              />
            )}
            {!!inactiveStatusCount && (
              <Badge
                key={ParkItemState.Inactive}
                variant={ParkStatesBadgeMapping[ParkItemState.Inactive]}
                size={BadgeSize.Meduim}
                text={
                  isDesktop ? (
                    <Trans
                      i18nKey={`pages.parks.card.itemsStates.${[
                        ParkItemState.Inactive,
                      ]}`}
                      values={{ count: inactiveStatusCount }}
                    />
                  ) : (
                    inactiveStatusCount
                  )
                }
                onClick={(e) =>
                  onBadgeClick(
                    e,
                    `?operationalStatus=${WindTurbineDtoOperationalStatusEnum.DEACTIVATED}`,
                  )
                }
                dataTestId="park-inactive-badge"
              />
            )}
            {!!errorStatusCount && (
              <Badge
                key={ParkItemState.Error}
                variant={ParkStatesBadgeMapping[ParkItemState.Error]}
                size={BadgeSize.Meduim}
                text={
                  isDesktop ? (
                    <Trans
                      i18nKey={`pages.parks.card.itemsStates.${[
                        ParkItemState.Error,
                      ]}`}
                      values={{ count: errorStatusCount }}
                    />
                  ) : (
                    errorStatusCount
                  )
                }
                onClick={(e) =>
                  onBadgeClick(e, `?status=${WindTurbineDtoStatusEnum.ERROR}`)
                }
                dataTestId="park-error-badge"
              />
            )}
          </Box>
          <StatsWrapper>
            <StatsItem>
              <Typography variant="bodyM">
                {convertWh(totalActiveEnergy || 0, t)}
              </Typography>
              <Typography variant="bodyXS" color="grey.600">
                {t('pages.parks.card.activeEnergy')}
              </Typography>
            </StatsItem>
          </StatsWrapper>
        </CountersWrapper>
      </MainContentWrapper>
      <WeatherPreview weather={data.weather} />
    </Wrapper>
  );
};
