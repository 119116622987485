/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssetDetailsDto } from '../models';
// @ts-ignore
import { AssetDto } from '../models';
// @ts-ignore
import { AssetNameDto } from '../models';
// @ts-ignore
import { AssetPublicIdsDto } from '../models';
// @ts-ignore
import { AssetUpdateDto } from '../models';
// @ts-ignore
import { GrafanaPanelParametersDto } from '../models';
/**
 * AssetControllerApi - axios parameter creator
 * @export
 */
export const AssetControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} assetPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset: async (assetPublicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetPublicId' is not null or undefined
            assertParamExists('deleteAsset', 'assetPublicId', assetPublicId)
            const localVarPath = `/asset/{assetPublicId}`
                .replace(`{${"assetPublicId"}}`, encodeURIComponent(String(assetPublicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} xApiKey 
         * @param {AssetPublicIdsDto} assetPublicIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existByCustomer: async (customerId: number, xApiKey: string, assetPublicIdsDto: AssetPublicIdsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('existByCustomer', 'customerId', customerId)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('existByCustomer', 'xApiKey', xApiKey)
            // verify required parameter 'assetPublicIdsDto' is not null or undefined
            assertParamExists('existByCustomer', 'assetPublicIdsDto', assetPublicIdsDto)
            const localVarPath = `/asset/exist-by-customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetPublicIdsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetPublicId 
         * @param {string} [panelTitle] 
         * @param {'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART'} [dashboardType] 
         * @param {string} [restorationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAssetGrafanaPanels: async (assetPublicId: string, panelTitle?: string, dashboardType?: 'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART', restorationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetPublicId' is not null or undefined
            assertParamExists('getAdminAssetGrafanaPanels', 'assetPublicId', assetPublicId)
            const localVarPath = `/asset/{assetPublicId}/grafana/admin-panels`
                .replace(`{${"assetPublicId"}}`, encodeURIComponent(String(assetPublicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (panelTitle !== undefined) {
                localVarQueryParameter['panelTitle'] = panelTitle;
            }

            if (dashboardType !== undefined) {
                localVarQueryParameter['dashboardType'] = dashboardType;
            }

            if (restorationId !== undefined) {
                localVarQueryParameter['restorationId'] = restorationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} restorationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminInvestigationGrafanaPanels: async (restorationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restorationId' is not null or undefined
            assertParamExists('getAdminInvestigationGrafanaPanels', 'restorationId', restorationId)
            const localVarPath = `/asset/investigation/grafana/panels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (restorationId !== undefined) {
                localVarQueryParameter['restorationId'] = restorationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminReportGrafanaPanels: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getAdminReportGrafanaPanels', 'requestBody', requestBody)
            const localVarPath = `/asset/admin-report/grafana/panels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AssetPublicIdsDto} assetPublicIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetDetails: async (assetPublicIdsDto: AssetPublicIdsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetPublicIdsDto' is not null or undefined
            assertParamExists('getAssetDetails', 'assetPublicIdsDto', assetPublicIdsDto)
            const localVarPath = `/asset/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetPublicIdsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetPublicId 
         * @param {string} [panelTitle] 
         * @param {'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART'} [dashboardType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetGrafanaPanels: async (assetPublicId: string, panelTitle?: string, dashboardType?: 'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetPublicId' is not null or undefined
            assertParamExists('getAssetGrafanaPanels', 'assetPublicId', assetPublicId)
            const localVarPath = `/asset/{assetPublicId}/grafana/panels`
                .replace(`{${"assetPublicId"}}`, encodeURIComponent(String(assetPublicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (panelTitle !== undefined) {
                localVarQueryParameter['panelTitle'] = panelTitle;
            }

            if (dashboardType !== undefined) {
                localVarQueryParameter['dashboardType'] = dashboardType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetPublicId 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetOrangePiHost: async (assetPublicId: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetPublicId' is not null or undefined
            assertParamExists('getAssetOrangePiHost', 'assetPublicId', assetPublicId)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getAssetOrangePiHost', 'xApiKey', xApiKey)
            const localVarPath = `/asset/{assetPublicId}/host`
                .replace(`{${"assetPublicId"}}`, encodeURIComponent(String(assetPublicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {AssetPublicIdsDto} assetPublicIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetServiceDetails: async (xApiKey: string, assetPublicIdsDto: AssetPublicIdsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getAssetServiceDetails', 'xApiKey', xApiKey)
            // verify required parameter 'assetPublicIdsDto' is not null or undefined
            assertParamExists('getAssetServiceDetails', 'assetPublicIdsDto', assetPublicIdsDto)
            const localVarPath = `/asset/service-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetPublicIdsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerReportGrafanaPanels: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getCustomerReportGrafanaPanels', 'requestBody', requestBody)
            const localVarPath = `/asset/customer-report/grafana/panels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetPublicId 
         * @param {AssetUpdateDto} assetUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset: async (assetPublicId: string, assetUpdateDto: AssetUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetPublicId' is not null or undefined
            assertParamExists('updateAsset', 'assetPublicId', assetPublicId)
            // verify required parameter 'assetUpdateDto' is not null or undefined
            assertParamExists('updateAsset', 'assetUpdateDto', assetUpdateDto)
            const localVarPath = `/asset/{assetPublicId}`
                .replace(`{${"assetPublicId"}}`, encodeURIComponent(String(assetPublicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetControllerApi - functional programming interface
 * @export
 */
export const AssetControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} assetPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAsset(assetPublicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAsset(assetPublicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} xApiKey 
         * @param {AssetPublicIdsDto} assetPublicIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existByCustomer(customerId: number, xApiKey: string, assetPublicIdsDto: AssetPublicIdsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.existByCustomer(customerId, xApiKey, assetPublicIdsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetPublicId 
         * @param {string} [panelTitle] 
         * @param {'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART'} [dashboardType] 
         * @param {string} [restorationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAssetGrafanaPanels(assetPublicId: string, panelTitle?: string, dashboardType?: 'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART', restorationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: GrafanaPanelParametersDto; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAssetGrafanaPanels(assetPublicId, panelTitle, dashboardType, restorationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} restorationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminInvestigationGrafanaPanels(restorationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: GrafanaPanelParametersDto; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminInvestigationGrafanaPanels(restorationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminReportGrafanaPanels(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<GrafanaPanelParametersDto>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminReportGrafanaPanels(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AssetPublicIdsDto} assetPublicIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetDetails(assetPublicIdsDto: AssetPublicIdsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetDetails(assetPublicIdsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetPublicId 
         * @param {string} [panelTitle] 
         * @param {'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART'} [dashboardType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetGrafanaPanels(assetPublicId: string, panelTitle?: string, dashboardType?: 'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: GrafanaPanelParametersDto; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetGrafanaPanels(assetPublicId, panelTitle, dashboardType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetNameDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetPublicId 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetOrangePiHost(assetPublicId: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetOrangePiHost(assetPublicId, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {AssetPublicIdsDto} assetPublicIdsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetServiceDetails(xApiKey: string, assetPublicIdsDto: AssetPublicIdsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetServiceDetails(xApiKey, assetPublicIdsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerReportGrafanaPanels(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<GrafanaPanelParametersDto>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerReportGrafanaPanels(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetPublicId 
         * @param {AssetUpdateDto} assetUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAsset(assetPublicId: string, assetUpdateDto: AssetUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAsset(assetPublicId, assetUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetControllerApi - factory interface
 * @export
 */
export const AssetControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AssetControllerApiDeleteAssetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset(requestParameters: AssetControllerApiDeleteAssetRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAsset(requestParameters.assetPublicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetControllerApiExistByCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existByCustomer(requestParameters: AssetControllerApiExistByCustomerRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.existByCustomer(requestParameters.customerId, requestParameters.xApiKey, requestParameters.assetPublicIdsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetControllerApiGetAdminAssetGrafanaPanelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAssetGrafanaPanels(requestParameters: AssetControllerApiGetAdminAssetGrafanaPanelsRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: GrafanaPanelParametersDto; }> {
            return localVarFp.getAdminAssetGrafanaPanels(requestParameters.assetPublicId, requestParameters.panelTitle, requestParameters.dashboardType, requestParameters.restorationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetControllerApiGetAdminInvestigationGrafanaPanelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminInvestigationGrafanaPanels(requestParameters: AssetControllerApiGetAdminInvestigationGrafanaPanelsRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: GrafanaPanelParametersDto; }> {
            return localVarFp.getAdminInvestigationGrafanaPanels(requestParameters.restorationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetControllerApiGetAdminReportGrafanaPanelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminReportGrafanaPanels(requestParameters: AssetControllerApiGetAdminReportGrafanaPanelsRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: Array<GrafanaPanelParametersDto>; }> {
            return localVarFp.getAdminReportGrafanaPanels(requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetControllerApiGetAssetDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetDetails(requestParameters: AssetControllerApiGetAssetDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AssetDetailsDto>> {
            return localVarFp.getAssetDetails(requestParameters.assetPublicIdsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetControllerApiGetAssetGrafanaPanelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetGrafanaPanels(requestParameters: AssetControllerApiGetAssetGrafanaPanelsRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: GrafanaPanelParametersDto; }> {
            return localVarFp.getAssetGrafanaPanels(requestParameters.assetPublicId, requestParameters.panelTitle, requestParameters.dashboardType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetNames(options?: AxiosRequestConfig): AxiosPromise<Array<AssetNameDto>> {
            return localVarFp.getAssetNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetControllerApiGetAssetOrangePiHostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetOrangePiHost(requestParameters: AssetControllerApiGetAssetOrangePiHostRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getAssetOrangePiHost(requestParameters.assetPublicId, requestParameters.xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetControllerApiGetAssetServiceDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetServiceDetails(requestParameters: AssetControllerApiGetAssetServiceDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AssetDetailsDto>> {
            return localVarFp.getAssetServiceDetails(requestParameters.xApiKey, requestParameters.assetPublicIdsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetControllerApiGetCustomerReportGrafanaPanelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerReportGrafanaPanels(requestParameters: AssetControllerApiGetCustomerReportGrafanaPanelsRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: Array<GrafanaPanelParametersDto>; }> {
            return localVarFp.getCustomerReportGrafanaPanels(requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetControllerApiUpdateAssetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset(requestParameters: AssetControllerApiUpdateAssetRequest, options?: AxiosRequestConfig): AxiosPromise<AssetDto> {
            return localVarFp.updateAsset(requestParameters.assetPublicId, requestParameters.assetUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteAsset operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiDeleteAssetRequest
 */
export interface AssetControllerApiDeleteAssetRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiDeleteAsset
     */
    readonly assetPublicId: string
}

/**
 * Request parameters for existByCustomer operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiExistByCustomerRequest
 */
export interface AssetControllerApiExistByCustomerRequest {
    /**
     * 
     * @type {number}
     * @memberof AssetControllerApiExistByCustomer
     */
    readonly customerId: number

    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiExistByCustomer
     */
    readonly xApiKey: string

    /**
     * 
     * @type {AssetPublicIdsDto}
     * @memberof AssetControllerApiExistByCustomer
     */
    readonly assetPublicIdsDto: AssetPublicIdsDto
}

/**
 * Request parameters for getAdminAssetGrafanaPanels operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiGetAdminAssetGrafanaPanelsRequest
 */
export interface AssetControllerApiGetAdminAssetGrafanaPanelsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiGetAdminAssetGrafanaPanels
     */
    readonly assetPublicId: string

    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiGetAdminAssetGrafanaPanels
     */
    readonly panelTitle?: string

    /**
     * 
     * @type {'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART'}
     * @memberof AssetControllerApiGetAdminAssetGrafanaPanels
     */
    readonly dashboardType?: 'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART'

    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiGetAdminAssetGrafanaPanels
     */
    readonly restorationId?: string
}

/**
 * Request parameters for getAdminInvestigationGrafanaPanels operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiGetAdminInvestigationGrafanaPanelsRequest
 */
export interface AssetControllerApiGetAdminInvestigationGrafanaPanelsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiGetAdminInvestigationGrafanaPanels
     */
    readonly restorationId: string
}

/**
 * Request parameters for getAdminReportGrafanaPanels operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiGetAdminReportGrafanaPanelsRequest
 */
export interface AssetControllerApiGetAdminReportGrafanaPanelsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetControllerApiGetAdminReportGrafanaPanels
     */
    readonly requestBody: Array<string>
}

/**
 * Request parameters for getAssetDetails operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiGetAssetDetailsRequest
 */
export interface AssetControllerApiGetAssetDetailsRequest {
    /**
     * 
     * @type {AssetPublicIdsDto}
     * @memberof AssetControllerApiGetAssetDetails
     */
    readonly assetPublicIdsDto: AssetPublicIdsDto
}

/**
 * Request parameters for getAssetGrafanaPanels operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiGetAssetGrafanaPanelsRequest
 */
export interface AssetControllerApiGetAssetGrafanaPanelsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiGetAssetGrafanaPanels
     */
    readonly assetPublicId: string

    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiGetAssetGrafanaPanels
     */
    readonly panelTitle?: string

    /**
     * 
     * @type {'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART'}
     * @memberof AssetControllerApiGetAssetGrafanaPanels
     */
    readonly dashboardType?: 'GENERAL' | 'CUSTOMER_GENERAL' | 'ERRORS_STATUSES' | 'CUSTOMER_REPORT' | 'ADMIN_REPORT' | 'ADMIN_VIEW_TAGS' | 'ADMIN_INVESTIGATION' | 'ADMIN_COMPARISON_CHART'
}

/**
 * Request parameters for getAssetOrangePiHost operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiGetAssetOrangePiHostRequest
 */
export interface AssetControllerApiGetAssetOrangePiHostRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiGetAssetOrangePiHost
     */
    readonly assetPublicId: string

    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiGetAssetOrangePiHost
     */
    readonly xApiKey: string
}

/**
 * Request parameters for getAssetServiceDetails operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiGetAssetServiceDetailsRequest
 */
export interface AssetControllerApiGetAssetServiceDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiGetAssetServiceDetails
     */
    readonly xApiKey: string

    /**
     * 
     * @type {AssetPublicIdsDto}
     * @memberof AssetControllerApiGetAssetServiceDetails
     */
    readonly assetPublicIdsDto: AssetPublicIdsDto
}

/**
 * Request parameters for getCustomerReportGrafanaPanels operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiGetCustomerReportGrafanaPanelsRequest
 */
export interface AssetControllerApiGetCustomerReportGrafanaPanelsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetControllerApiGetCustomerReportGrafanaPanels
     */
    readonly requestBody: Array<string>
}

/**
 * Request parameters for updateAsset operation in AssetControllerApi.
 * @export
 * @interface AssetControllerApiUpdateAssetRequest
 */
export interface AssetControllerApiUpdateAssetRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetControllerApiUpdateAsset
     */
    readonly assetPublicId: string

    /**
     * 
     * @type {AssetUpdateDto}
     * @memberof AssetControllerApiUpdateAsset
     */
    readonly assetUpdateDto: AssetUpdateDto
}

/**
 * AssetControllerApi - object-oriented interface
 * @export
 * @class AssetControllerApi
 * @extends {BaseAPI}
 */
export class AssetControllerApi extends BaseAPI {
    /**
     * 
     * @param {AssetControllerApiDeleteAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public deleteAsset(requestParameters: AssetControllerApiDeleteAssetRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).deleteAsset(requestParameters.assetPublicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetControllerApiExistByCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public existByCustomer(requestParameters: AssetControllerApiExistByCustomerRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).existByCustomer(requestParameters.customerId, requestParameters.xApiKey, requestParameters.assetPublicIdsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetControllerApiGetAdminAssetGrafanaPanelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAdminAssetGrafanaPanels(requestParameters: AssetControllerApiGetAdminAssetGrafanaPanelsRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAdminAssetGrafanaPanels(requestParameters.assetPublicId, requestParameters.panelTitle, requestParameters.dashboardType, requestParameters.restorationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetControllerApiGetAdminInvestigationGrafanaPanelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAdminInvestigationGrafanaPanels(requestParameters: AssetControllerApiGetAdminInvestigationGrafanaPanelsRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAdminInvestigationGrafanaPanels(requestParameters.restorationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetControllerApiGetAdminReportGrafanaPanelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAdminReportGrafanaPanels(requestParameters: AssetControllerApiGetAdminReportGrafanaPanelsRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAdminReportGrafanaPanels(requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetControllerApiGetAssetDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAssetDetails(requestParameters: AssetControllerApiGetAssetDetailsRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAssetDetails(requestParameters.assetPublicIdsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetControllerApiGetAssetGrafanaPanelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAssetGrafanaPanels(requestParameters: AssetControllerApiGetAssetGrafanaPanelsRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAssetGrafanaPanels(requestParameters.assetPublicId, requestParameters.panelTitle, requestParameters.dashboardType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAssetNames(options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAssetNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetControllerApiGetAssetOrangePiHostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAssetOrangePiHost(requestParameters: AssetControllerApiGetAssetOrangePiHostRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAssetOrangePiHost(requestParameters.assetPublicId, requestParameters.xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetControllerApiGetAssetServiceDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAssetServiceDetails(requestParameters: AssetControllerApiGetAssetServiceDetailsRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAssetServiceDetails(requestParameters.xApiKey, requestParameters.assetPublicIdsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetControllerApiGetCustomerReportGrafanaPanelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getCustomerReportGrafanaPanels(requestParameters: AssetControllerApiGetCustomerReportGrafanaPanelsRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getCustomerReportGrafanaPanels(requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetControllerApiUpdateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public updateAsset(requestParameters: AssetControllerApiUpdateAssetRequest, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).updateAsset(requestParameters.assetPublicId, requestParameters.assetUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}
