import { FC } from 'react';
import { Trans } from 'react-i18next';

import { WTLinkStyled } from 'components/shared/HistoryLog/styles';
import { UserChangedWindTurbineNameDto } from 'openapi-api/admin-service';

export const WindTurbineDetailsChangedTemplate: FC<
  UserChangedWindTurbineNameDto & { locationId?: number }
> = ({
  eventDateTime,
  locationId,
  publicId,
  currentAssetNameForUser,
  previousAssetNameForUser,
  subjectUser,
}) => {
  return (
    <>
      <Trans
        i18nKey="components.historyLog.templates.user.activities.windTurbineDetailsChanged"
        values={{
          // TODO: replace with subjectUser.username after BE update
          username: subjectUser?.userId,
          eventDateTime,
        }}
        components={{
          assetLink: (
            <WTLinkStyled
              locationId={locationId}
              publicId={String(publicId!)}
              linkText={currentAssetNameForUser}
            />
          ),
          bold: <strong />,
        }}
      />
      <br />
      <Trans
        i18nKey="components.historyLog.templates.user.activities.windTurbineNameChanged"
        values={{
          previous: previousAssetNameForUser,
          current: previousAssetNameForUser,
        }}
        components={{ bold: <strong /> }}
      />
    </>
  );
};
