import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import useUser from 'contexts/user';
import {
  UserChangedAssetOperationalStatusDto,
  UserChangedAssetOperationalStatusDtoAllOfOperationalStatusEnum,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const OperationalStatusChangedTemplate: FC<
  UserChangedAssetOperationalStatusDto
> = ({ assetNameForUser, operationalStatus, eventDateTime, subjectUser }) => {
  const { user } = useUser();
  const { t } = useTranslation();

  const operationalStatusText = useMemo(
    () =>
      operationalStatus ===
      UserChangedAssetOperationalStatusDtoAllOfOperationalStatusEnum.ACTIVATED
        ? t('pages.turbine.on')
        : t('pages.turbine.off'),
    [operationalStatus, t],
  );

  return (
    <Trans
      i18nKey="components.historyLog.templates.asset.activities.operationalStatusChanged"
      values={{
        assetName: assetNameForUser,
        status: operationalStatusText.toUpperCase(),
        eventDateTime,
      }}
      components={{
        userLink:
          subjectUser?.userRole === UserUserRoleEnum.USER_PORTAL_SUPER_ADMIN ? (
            // TODO: replace user.username with subjectUser.username after BE update
            <Typography variant="bodyMStrong">{user.username}</Typography>
          ) : (
            <UserIdLink
              to={routePaths.users.profile(subjectUser?.id!).historyLog}
            >
              {/* TODO: replace with subjectUser.username after BE update */}
              {subjectUser?.userId}
            </UserIdLink>
          ),
        bold: <strong />,
      }}
    />
  );
};
