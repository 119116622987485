import { Box, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  NotFoundInnerWrapper,
  NotFoundTitleStyled,
  NotFoundWrapper,
  TextWrapper,
} from './styles';
import CommonButton from 'components/common/CommonButton';
import routePaths from 'constants/routePaths';

export const NotFound: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate(routePaths.parks.root);
  }, [navigate]);

  return (
    <NotFoundWrapper>
      <NotFoundInnerWrapper>
        <NotFoundTitleStyled />
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          gap={{ mobile: 5, desktop: 6.5 }}
        >
          <TextWrapper>
            <Typography variant="h2">
              {t('components.notFound.title')}
            </Typography>
            <Typography variant="h3" fontWeight="400">
              {t('components.notFound.subtitle')}
            </Typography>
          </TextWrapper>
          <CommonButton variant="outlined" onClick={onButtonClick}>
            {t('components.notFound.buttonText')}
          </CommonButton>
        </Box>
      </NotFoundInnerWrapper>
    </NotFoundWrapper>
  );
};
